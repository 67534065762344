<template>
  <tr>
    <td class="nowrap-column">
      <strong>{{ ads_car.id }}</strong>
    </td>
    <td class="nowrap-column">
      <small>{{ ads_car.date_title }}</small>
    </td>
    <td class="nowrap-column">
      {{ ads_car.city ? ads_car.city.title : '' }}
    </td>
    <td class="nowrap-column">
      {{ ads_car.model.name }}
    </td>
    <td class="nowrap-column">
      {{ ads_car.mark.name }}
    </td>
    <td class="nowrap-column">
      {{ ads_car.year }}
    </td>
    <td class="nowrap-column">
      <strong>{{ ads_car.price }}</strong>$
    </td>
    <td class="nowrap-column">
      <router-link :to="{ name: 'client.details', params: { clientId: ads_car.client_id }}">
        <i class="fas fa-user-circle"></i>
        <strong class="font-scale">
          {{ ads_car.client ? ads_car.client.name : ads_car.client_name }}
        </strong>
      </router-link>
    </td>
    <td class="nowrap-column">
      <a :href="'tel:+' + (ads_car.client ? ads_car.client.phone : ads_car.client_phone)">
        <strong>{{ ads_car.client ? ads_car.client.phone : ads_car.client_phone }}</strong>
      </a>
      <clipboard-block :data="(ads_car.client ? ads_car.client.phone : ads_car.client_phone)" custom_class="btn-default"/>
    </td>
    <td class="nowrap-column">
      {{ ads_car.ads_count }}
    </td>
    <td class="nowrap-column">
      <strong>{{ ads_car.ad_source }}</strong>
    </td>
    <td class="nowrap-column">
      <a :href="(ads_car.ad_source === 'AutoRIA' ? 'https://auto.ria.com/ru' : 'https://olx.ua') + ads_car.link"
         class="btn btn-info" target="_blank">
        <i class="fas fa-external-link-square-alt"></i>
      </a>
    </td>
    <td class="nowrap-column">
      <small>{{ ads_car.created_date }}</small>
    </td>
    <td class="nowrap-column">
      <button v-if="!ads_car.task_id"
              class="btn bg-purple" style="margin: 3px 2px 2px 3px"
              title="Создать задачу" type="button"
              v-on:click="addAdCarTask(ads_car.id)">
        <i class="fas fa-headset"></i>
      </button>
      <router-link v-else
                   :to="{ name: 'task.details', params: { taskId: ads_car.task_id, currentTab: 'История' }}"
                   class="btn btn-success margin-l-5"
                   title="Детали задачи"
                   type="button">
        <i class="fa fa-info-circle"></i>
      </router-link>
    </td>
  </tr>
</template>

<script>
import ClipboardBlock from './../../components/blocks/clipboard-block.vue';

export default {
  name: 'ad-car-item',

  components: {
    ClipboardBlock,
  },

  props: {
    ads_car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    addAdCarTask(adsCarId) {
      this.$dialog.confirm('Вы уверены что хотите создать задачу?', this.$dialogOptions).then(() => {
        API.apiClient.post('/ads_car/' + adsCarId + '/task/add').then(() => {
          this.EventBus.$emit('table-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>
button:hover {
  opacity: 0.8;
}
</style>
